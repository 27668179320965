body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-caret .dropdown-toggle::after,
.no-caret .dropdown-toggle::before {
  display: none !important;
}

.assigned-provider {
  color: #3881cf;
  font-size: 15px;
  font-weight: 500;
  padding: 0.3rem 0.5rem;
  border-radius: 0.2rem;
}

.assigned-provider:hover {
  color: #1a5f9c;
  background-color: rgb(250, 246, 246);
}

.card-date {
  font-size: 0.9rem;
  color: #6c757d;
  font-weight: 500;
}

.provider-section {
  display: flex;
  justify-content:flex-start;
  align-items: flex-start;
  margin-bottom: 0.25rem;
  flex-direction: column;
}

.section-title {
  color: #a9acae;
  font-weight: 600;
  font-size: small;
  margin-bottom: 0.25rem;
}

.section-title.provider {
  color: #1a80c9;
  cursor: pointer;
}
